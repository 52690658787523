/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import {
    makeStyles,
} from '@material-ui/core';
import {
    arrayOf, shape, string, number, func, object,
    array,
} from 'prop-types';

import { useSelector } from 'react-redux';
import parseFontFamilyExtension from '../../../../../helpers/contentstack/parseFontFamilyExtension';
import noop from '../../../../../helpers/noop';
import ConnectedPersonalizedHomePageProduct from '../PersonalizedHomePageProduct/PersonalizedHomePageProduct';
import { getSSRDeviceType } from '../../../../../../state/ducks/App/App-Selectors';
import { getBrandName } from '../../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import BlockHeading from '../../../../ContentTypeComponents/BlockHeading';

const useStyles = makeStyles((theme) => ({
    root: (styles) => ({
        padding: styles?.padding || '32px 0px',
        [theme.breakpoints.down(600)]: {
            padding: styles?.padding || '24px 0px',
        },
    }),
    title: (styles) => ({
        color: styles?.titleFontColor || '#000000',
        fontFamily: styles?.titleFontFamily || theme?.typography?.fontFamily,
        fontSize: styles?.titleFontSize || '1.625rem',
        fontStyle: styles?.titleFontStyle || 'normal',
        // add other header tags here ONLY if needed for SEO/styling
        '& h3, & p': {
            margin: styles?.titleMargin || '0 0 10px',
            padding: '0px',
            fontFamily: styles?.titleFontFamily || theme?.typography?.fontFamily,
            textAlign: styles?.textAlign || 'center',
            fontSize: styles?.titleFontSize || '1.625rem',
            fontWeight: styles?.titleFontWeight || '400',
            letterSpacing: '.4px',
            color: 'inherit',
        },

        [theme.breakpoints.down('xs')]: {
            fontSize: styles?.titleFontSize || '1rem',
            '& h3, & p': {
                fontSize: styles?.titleFontSize || '1rem',
            },
        },

    }),

    titleContainer: (styles) => ({
        maxWidth: '1400px',
        margin: '0 auto',
        padding: '0 10px',

        [theme.breakpoints.up(1024)]: {
            paddingLeft: '20px',
            paddingRight: '20px',
        },

        [theme.breakpoints.up(1400)]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '& h3': {
            color: styles?.titleFontColor || '#000000',
            margin: styles?.titleMargin || '0 0 10px',
            padding: '0px',
            fontFamily: styles?.titleFontFamily || theme?.typography?.fontFamily,
            textAlign: styles?.textAlign || 'center',
        },
        '& h2': {
            color: styles?.titleFontColor || '#000000',
            margin: styles?.titleMargin || '0px 18px 16p',
            padding: styles?.padding || '0px',
            fontFamily: styles?.titleFontFamily || theme?.typography?.fontFamily,
            textAlign: styles?.textAlign || 'center',
            fontSize: styles?.titleFontSize || '28px',
            fontWeight: '600',
            lineHeight: '35px',
            letterSpacing: '0.01em',
            textUnderlinePosition: 'from-font',
            textDecorationSkipInk: 'none',
            [theme.breakpoints.down(600)]: {
                fontSize: styles?.titleFontSize || '21px',
                lineHeight: '28px',
            },
        },
    }),
    productContainer: {
        margin: '0 auto',
        padding: '0 10px 10px 10px',
        width: '100%',
        height: 'auto',
        maxWidth: '1400px',
        overflow: 'auto',
        whiteSpace: 'nowrap',
        display: 'flex',
        flexWrap: 'nowrap',

        [theme.breakpoints.up(1024)]: {
            paddingLeft: '20px',
            paddingRight: '20px',
        },

        [theme.breakpoints.up(1400)]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        '&$flexContainer': {
            padding: '0px',
            overflow: 'visible',
        },
        '& [data-component="productName"]': {
            [theme.breakpoints.down(600)]: {
                margin: 0,
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                whiteSpace: 'normal',
            },
        },
    },
    flexContainer: {
        display: 'flex',
        justifyContent: 'center',

    },
    productCard: ({ noOfProductsToShow }) => ({
        width: `calc((100% - ${10 * noOfProductsToShow}px) / ${noOfProductsToShow})`,
        marginRight: '10px',
        // display: 'inline-table',
        whiteSpace: 'pre-line',
        flex: '0 0 auto',

        '& a p span': {
            whiteSpace: 'normal',
        },

        '&:last-child': {
            marginRight: '0',
        },

        [theme.breakpoints.up(1024)]: {
            width: `calc((100% - ${20 * noOfProductsToShow}px) / ${noOfProductsToShow})`,
            marginRight: '20px',
        },

        [theme.breakpoints.up(1400)]: {
            width: `${(1400 - (20 * (noOfProductsToShow - 1))) / noOfProductsToShow}px`,
        },
    }),
}));

const PersonalizedHomePageCarouselBuilder = ({
    blockData: {
        productsToShow,
        title,
        titleFontColor,
        titleFontSize,
        titleFontStyle,
        titleFontFamily,
        titleFontWeight,
        titleMargin,
        alignTitle,
        padding,
    },
    products,
    demandType,
    productClickCallback,
    eventTrackingData,
    productStyleConfig,
}) => {
    const deviceType = useSelector(getSSRDeviceType);
    const brandName = useSelector(getBrandName);

    const getProductsToShow = () => {
        const temp = {
            desktop: productsToShow?.desktop || 6,
            mobile: productsToShow?.mobile || 2.5,
            tablet: productsToShow?.tablet || 3.2,
        };

        // eslint-disable-next-line no-nested-ternary
        return deviceType === 'desktop' ? temp.desktop : deviceType === 'tablet' ? temp.tablet : temp.mobile;
    };

    const noOfProductsToShow = getProductsToShow();
    const keepInCenter = noOfProductsToShow >= (products?.length);

    const styles = {
        titleMargin,
        titleFontColor,
        titleFontSize,
        titleFontStyle,
        titleFontFamily: !titleFontFamily && brandName === 'harryanddavid' ? 'Crimson, Garamond, Times, serif' : parseFontFamilyExtension(titleFontFamily),
        titleFontWeight,
        textAlign: alignTitle,
        padding,
        noOfProductsToShow,
    };

    const classes = useStyles(styles);

    if (products?.length > 0) {
        return (
            <div className={classes.root} data-testid="carousel-builder">
                <div className={classes.titleContainer}>
                    <BlockHeading heading={title} />
                </div>
                <div className={`${classes.productContainer} ${keepInCenter ? classes.flexContainer : ''}`}>
                    {products?.map((product) => {
                        const {
                            name, image, seo: { url }, skuPriceRange, availability, partNumber, brandId, isPassportEligible,
                        } = product;
                        let link = url || '';
                        if (demandType && url) {
                            link = link.indexOf('?') >= 0 ? `${link}&demandType=${demandType}` : `${link}?demandType=${demandType}`;
                        }

                        return (
                            <div className={classes.productCard}>
                                <ConnectedPersonalizedHomePageProduct
                                    name={name}
                                    url={link}
                                    image={image}
                                    skuPriceRange={skuPriceRange}
                                    availability={availability}
                                    partNumber={partNumber}
                                    showSimpleProductRedesignAbTest={false}
                                    productBrand={brandId}
                                    productClickCallback={() => productClickCallback(partNumber)}
                                    productSkus={product.productSkus}
                                    eventTrackingData={eventTrackingData}
                                    productStyleConfig={productStyleConfig}
                                    priceRangeLayout={productStyleConfig?.price_range_layout || []}
                                    isPassportEligible={isPassportEligible}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return null;
};

PersonalizedHomePageCarouselBuilder.propTypes = {
    products: arrayOf(shape({
        id: string.isRequired,
        image: shape({
            path: string.isRequired,
            name: string.isRequired,
        }).isRequired,
        name: string.isRequired,
        skuPriceRange: shape({
            retail: arrayOf(shape({
                value: number.isRequired,
            })).isRequired,
            sale: arrayOf(shape({
                value: number.isRequired,
            })).isRequired,
        }).isRequired,
        seo: shape({
            url: string.isRequired,
        }).isRequired,
    })).isRequired,
    blockData: shape({
        title: string,
        titleFontColor: string,
        titleFontSize: string,
        titleFontStyle: string,
        titleFontFamily: string,
        titleFontWeight: string,
        titleMargin: string,
        padding: string,
        align_title: string,
        productsToShow: shape({
            desktop: number,
            mobile: number,
            tablet: number,
        }),
    }),
    title: string,
    demandType: string,
    productClickCallback: func,
    eventTrackingData: object,
    productStyleConfig: array,
};

PersonalizedHomePageCarouselBuilder.defaultProps = {
    blockData: {
        title: '',
        titleFontColor: '',
        titleFontSize: '',
        titleFontStyle: '',
        titleFontFamily: '',
        titleFontWeight: '',
        titleMargin: '',
        padding: '',
        align_title: '',
        productsToShow: null,
    },
    title: '',
    demandType: '',
    productClickCallback: noop,
    eventTrackingData: {},
    productStyleConfig: [],
};

export default PersonalizedHomePageCarouselBuilder;
